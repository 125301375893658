<template>
    <div>
        <navBar></navBar>
        <drawer v-if="display"></drawer>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import navBar from './navBar/navBar.vue';
import drawer from './navDrawer/drawer.vue';
export default {
    name: 'navigation',
    components: {
        navBar,
        drawer
    },
    computed: {
        ...mapGetters(['userProfile']),
        display() {
            console.log('User Profile: ', this.userProfile)
            if (this.userProfile.data().userRole === 'Super Admin' || this.userProfile.data().userRole === 'Admin') {
                return true
            }
            else if (this.userProfile.data().groups.length > 0) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>