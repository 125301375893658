<template>
  <v-app class="main">
    <navigation v-if="dataLoaded && !isGuest" v-resize="onResize"> </navigation>
    <v-main>
      <router-view v-if="showContent && dataLoaded" />
      <loading v-else> </loading>
    </v-main>
    <dashFooter></dashFooter>
    <snackBar></snackBar>
    <announcement></announcement>
  </v-app>
</template>

<script>
import navigation from './components/navigation/navigation.vue';
import loading from './components/loading.vue';
import dashFooter from './components/footer.vue';
import snackBar from './components/notifications/snackBar.vue';
import announcement from './components/notifications/announcement.vue';

import { mapGetters } from 'vuex';
import router from './router';
export default {
  name: 'App',
  components: {
    navigation,
    loading,
    dashFooter,
    snackBar,
    announcement
  },
  data() {
    return {
      showContent: false,
      dataLoaded: false,
      isGuest: false
    }
  },
  computed: {
    ...mapGetters(['userProfile', 'appData', 'userRoles', 'userRole'])
  },
  watch: {
    userProfile(newVal) {
      if (this.userRole === null) {
        this.isGuest = false
        this.dataLoaded = false
      }
      if (!this.dataLoaded) {
        console.log('New Val of User Profile: ', newVal.data())
        if (this.userProfile.data().userRole === 'Super Admin' || this.userProfile.data().userRole === 'Admin') {
          this.$store.dispatch('getUserRoles').then(() => {
            this.$store.commit('setUserRole', this.userProfile.data().userRole)
            const role = this.userRoles.filter(role => role.data.name === this.userProfile.data().userRole)
            this.$store.commit('setAssignedRole', role[0])
          })
          this.$store.dispatch('getAllRegisteredUsers')
          this.$store.dispatch('getAllRegisteredKiosks')
          this.$store.dispatch("getUserGroups").then(() => {
            this.showContent = true
            setTimeout(() => { this.dataLoaded = true; this.loading = false }, 1000)
          })
        } else {
          this.$store.dispatch('getUserRoles').then(() => {
            const role = this.userRoles.filter(role => role.data.name === this.userProfile.data().userRole)
            this.$store.commit('setAssignedRole', role[0])
            this.$store.commit('setUserRole', this.userProfile.data().userRole)
          })
          this.$store.dispatch('getAssignedUserGroups').then(() => {
            this.showContent = true;
            setTimeout(() => { this.dataLoaded = true; this.loading = false }, 1000)
          })
        }
      } else {
        if (this.userRole !== null) {
          if (this.userProfile.data().userRole !== this.userRole) {
            this.$store.commit('setAlert', {
              show: true,
              message: 'The Admin has updated your role. Please reload to get the update.'
            })
            this.$store.commit('setUserRole', this.userRole)
          }
        }
      }
    }
  },
  beforeCreate() {
    this.$store.dispatch("getAppData").then(() => {
      if (this.appData.initiated) {
        this.$vuetify.theme.themes.light = this.appData.themes.light;
      }
      console.log('Current Route: ', router.currentRoute)
      if (router.currentRoute.meta.requiresGuest) {
        this.dataLoaded = true
        this.isGuest = true
      } else {
        this.isGuest = false
      }
      this.showContent = true
    });
  },
  methods: {
    onResize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          this.$store.commit('setScreenSize', 'xs')
          this.$store.dispatch('calculateAppDimensions', 'xs')
          break
        case 'sm':
          this.$store.commit('setScreenSize', 'sm')
          this.$store.dispatch('calculateAppDimensions', 'sm')
          break
        case 'md':
          this.$store.commit('setScreenSize', 'md')
          this.$store.dispatch('calculateAppDimensions', 'md')
          break
        case 'lg':
          this.$store.commit('setScreenSize', 'lg')
          this.$store.dispatch('calculateAppDimensions', 'lg')
          break
        case 'xl':
          this.$store.commit('setScreenSize', 'xl')
          this.$store.dispatch('calculateAppDimensions', 'xl')
          break
      }
      this.$store.commit("setWindowDimensions", {
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
  }
};
</script>
<style>
.main {
  background-color: #f5f5f5 !important;
}

body::-webkit-scrollbar {
  display: none;
}

.v-card::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}

.v-card::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #6b1530;
  height: 2px
}

.v-data-table__wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}

.v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #6b1530;
}

.v-sheet::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}

.v-sheet::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #6b1530;
}

.miniplayer {
  z-index: 99999999 !important;
  padding-bottom: 30px;
  position: absolute;
  bottom: 1cm;
  right: 1cm;
}
</style>