import * as fb from '../../firebase/index.js'
import moment from 'moment';

export default {
    state: {
        chats: [],

    },
    getters: {
        getChats: state => state.chats,
    },
    mutations: {
        setChatData(state, data) {
            state.chats.push(data);
        },
        clearChatData(state) {
            state.chats = [];
        }
    },
    actions: {
        fetchChatData({ commit }, range) {
            return new Promise((response, reject) => {
                let queryRef;
                let today = new Date();
                today.setHours(0, 0, 0)
                console.log("TODAY:", today)
                switch (range) {
                    case "Today":
                        var yesterday = new Date(today.setDate(today.getDate() - 1))
                        yesterday.setHours(23, 59, 59)
                        console.log("YESTERDAY:", yesterday)
                        queryRef = fb.userCollection.where("createdAt", ">", yesterday).orderBy("createdAt", "desc")
                        break;
                    case "Yesterday":
                        var dayBeforeYesterday = new Date(today.setDate(today.getDate() - 2))
                        dayBeforeYesterday.setHours(23, 59, 59)
                        console.log("dayBeforeYesterday:", dayBeforeYesterday)
                        today = new Date();
                        today.setHours(0, 0, 0)
                        console.log("TODAY:", today)
                        queryRef = fb.userCollection.where("createdAt", ">", dayBeforeYesterday).where("createdAt", "<", today).orderBy("createdAt", "desc")
                        break;
                    case "Last 7 Days":
                        var lastSevenDays = new Date(today.setDate(today.getDate() - 7))
                        lastSevenDays.setHours(23, 59, 59)
                        console.log("lastSevenDays:", lastSevenDays)
                        queryRef = fb.userCollection.where("createdAt", ">", lastSevenDays).orderBy("createdAt", "desc")
                        break;
                    case "Last 30 Days":
                        var lastThirtyDays = new Date(today.setDate(today.getDate() - 30))
                        lastThirtyDays.setHours(23, 59, 59)
                        console.log("lastThirtyDays:", lastThirtyDays)
                        queryRef = fb.userCollection.where("createdAt", ">", lastThirtyDays).orderBy("createdAt", "desc")
                        break;
                    default:
                        break;
                }

                queryRef.get()
                    .then((querySnapshot) => {
                        commit('clearChatData');
                        querySnapshot.forEach((doc) => {
                            // doc.data() is never undefined for query doc snapshots
                            console.log(doc.id, " => ", doc.data());

                            commit('setChatData', doc.data())

                        });
                        response('Sessions Fetched Successfully');
                    })
                    .catch((error) => {
                        console.log("Error getting documents: ", error);
                        reject(error)
                    });

            })
        }

    }
}