import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database'

const firebaseConfig = {
    apiKey: "AIzaSyAEAk67KYavq3vHOht5AA4Zv_oKljK-mxo",
    authDomain: "florida-dist-11.firebaseapp.com",
    projectId: "florida-dist-11",
    storageBucket: "florida-dist-11.appspot.com",
    messagingSenderId: "537737103931",
    appId: "1:537737103931:web:c1c9edf2b0e8dc4dffea1e",
    measurementId: "G-RWWJZJNVPL",
    databaseURL: "https://florida-dist-11-default-rtdb.firebaseio.com"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();

const userCollection = db.collection('users');
const appDataCollection = db.collection('appData');
const invitedUsersCollection = db.collection('invitedUsers');
const userRolesCollection = db.collection('userRoles');
const invitedKiosksCollection = db.collection('invitedKiosks');
const kioskCollection = db.collection('kiosks')
const userGroupsCollection = db.collection('userGroups')
const registeredUsersCollection = db.collection('registeredUsers')
export {
    db,
    auth,
    firebase,
    userCollection,
    appDataCollection,
    invitedUsersCollection,
    userRolesCollection,
    invitedKiosksCollection,
    kioskCollection,
    userGroupsCollection,
    registeredUsersCollection
}