import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from "@/firebase";
import store from '@/store'
import Dashboard from '@/views/dashboard.vue'
import Login from '@/views/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
          title: 'Home'
        }
      },
      {
        path: '/userManagement',
        name: 'userManagement',
        component: () => import('@/views/management/userManagement.vue'),
        meta: {
          title: 'User Management'
        }
      },
      {
        path: '/groupManagement',
        name: 'groupManagement',
        component: () => import('@/views/management/groupManagement.vue'),
        meta: {
          title: 'Group Management'
        }
      },
      {
        path: '/roleManagement',
        name: 'roleManagement',
        component: () => import('@/views/management/roleManagement.vue'),
        meta: {
          title: 'Role Management'
        }
      },
      {
        path: '/kioskManagement',
        name: 'kioskManagement',
        component: () => import('@/views/management/kioskManagement.vue'),
        meta: {
          title: 'Kiosk Management'
        }
      },
      {
        path: '/kioskAnalytics',
        name: 'kioskAnalytics',
        component: () => import('@/views/management/kioskAnalytics.vue'),
        meta: {
          title: 'Kiosk Analytics'
        }
      },
      {
        path: '/userAnalytics',
        name: 'userAnalytics',
        component: () => import('@/views/management/userAnalytics.vue'),
        meta: {
          title: 'User Analytics'
        }
      },
      {
        path: '/chatAnalytics',
        name: 'chatAnalytics',
        component: () => import('@/views/management/chatAnalytics.vue'),
        meta: {
          title: 'Chat Analytics'
        }
      },
      {
        path: '/myProfile',
        name: 'profile',
        component: () => import('@/views/profile.vue'),
        meta: {
          title: 'My Profile'
        }
      },
      // {
      //   path: '/liveCall',
      //   name: 'liveCall',
      //   component: () => import('@/views/liveCall.vue'),
      //   meta: {
      //     title: 'Live Call'
      //   }
      // },
      {
        path: '/configurations',
        name: 'configurations',
        component: () => import('@/views/management/configurations.vue'),
        meta: {
          title: 'Configuration'
        }
      },

    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresGuest: true,
      title: 'Login'
    }
  },
  {
    path: '/adminLogin',
    name: 'adminLogin',
    component: () => import('../views/adminLogin.vue'),
    meta: {
      requiresGuest: true,
      title: 'Admin Login'
    }
  },
  {
    path: '/registration/:id',
    name: 'Registration',
    component: () => import('../views/registrations/invitedUserRegistration'),
    meta: {
      requiresGuest: true,
      title: 'Invited User Registeration'
    }
  },
  {
    path: '/kioskRegistration/:id',
    name: 'kioskRegistration',
    component: () => import('../views/registrations/invitedKioskRegistration.vue'),
    meta: {
      requiresGuest: true,
      title: 'Kiosk Registeration'
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const requiresAdmin = to.matched.some(x => x.meta.requiresAdmin);

  if (requiresAuth && !auth.currentUser) {
    store.dispatch('getAppData').then(response => {
      if (response === 'fetched') {
        next('/login')
      } else {
        next('/adminLogin')
      }
    })
  }
  else {
    next();
  }
})

router.afterEach((to, from) => {
  const DEFAULT_TITLE = 'Dashboard';

  Vue.nextTick(() => {
    document.title = ` ${to.meta.title} | ${DEFAULT_TITLE} `;
  });
});

export default router
