import * as fb from '../../firebase/index.js'
export default {
    state: {
        allRegisteredUsers: [],
        allInvitedUsers: [],
        invitedUserProfile: {}
    },
    getters: {
        allRegisteredUsers: state => state.allRegisteredUsers,
        allInvitedUsers: state => state.allInvitedUsers,
        invitedUserProfile: state => state.invitedUserProfile,

    },
    mutations: {
        setAllRegisteredUsers(state, user) {
            state.allRegisteredUsers.push(user);
        },
        clearRegisteredUsers(state) {
            state.allRegisteredUsers = []
        },
        setAllInvitedUsers(state, users) {
            state.allInvitedUsers = users;
        },
        setInvitedUserProfile(state, data) {
            state.invitedUserProfile = data;
        },
    },
    actions: {
        // This action will be called when logged In user role will be Super Admin or Admin
        getAllRegisteredUsers({ commit, getters }) {
            commit('clearRegisteredUsers')
            return new Promise((resolve, reject) => {
                fb.registeredUsersCollection.get().then(users => {
                    users.docs.forEach(user => {
                        let userListener = user.id.toString()
                        // Each user will have its individual snapshot
                        userListener = fb.registeredUsersCollection.doc(user.id).onSnapshot(snapshot => {
                            // Below is added to remove existing entry
                            let index = getters.allRegisteredUsers.findIndex(user => user.id === snapshot.id)
                            if (index !== -1) {
                                getters.allRegisteredUsers.splice(index, 1)
                            }
                            // The below check is required for - If the user is deleted from DB, onSnapshot returns the deleted user with Exists flag as false
                            // Data will only be stored when Exists flag is true
                            if (snapshot.exists) {
                                commit('setAllRegisteredUsers', snapshot);
                            }
                        })
                    })
                })
            })
        },

        // This action will be called for all the other user roles
        getAssignedRegisteredUsers({ commit, getters }, usersList) {
            usersList.forEach(user => {
                // The below check is added to avoid redundant snapshots
                // Need to find a better way  
                if (getters.allRegisteredUsers.findIndex(user1 => user1.id === user) === -1) {
                    let userListener = user.toString()
                    userListener = fb.registeredUsersCollection.doc(user).onSnapshot(snapshot => {
                        let index = getters.allRegisteredUsers.findIndex(user => user.id === snapshot.id)
                        if (index !== -1) {
                            getters.allRegisteredUsers.splice(index, 1)
                        }
                        if (snapshot.exists) {
                            commit('setAllRegisteredUsers', snapshot);
                        }
                    })
                }
            })
        },
        // The action will fetch all the invited users from the database
        getAllInvitedUsers({ commit }) {
            return new Promise((resolve, reject) => {
                fb.invitedUsersCollection.onSnapshot(snapshot => {
                    if (snapshot.empty) console.log('Empty user collection');
                    commit('setAllInvitedUsers', snapshot.docs);
                    resolve('Fetched all Invited Users in collection');
                }, error => {
                    reject(error.message);
                })
            })
        },

        // ------- Update Registered OR Invited Users

        // Registered User
        updateRegisteredUserInformation({ state }, userData) {
            return new Promise((resolve, reject) => {
                fb.registeredUsersCollection.doc(userData.id).update({
                    name: userData.name,
                    userRole: userData.userRole,
                    email: userData.email
                })
                    .then(() => {
                        resolve('User Information updated successfully.')
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },

        // Invited User  
        updateInvitedUserInformation({ state }, userData) {
            return new Promise((resolve, reject) => {
                fb.invitedUsersCollection.doc(userData.id).update({
                    name: userData.name,
                    userRole: userData.userRole,
                    email: userData.email,
                    assignedKiosks: userData.assignedKiosks
                })
                    .then(() => {
                        resolve('User Information updated successfully.')
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },

        // ------- Delete Registered OR Invited Users

        // Registered
        deleteRegisteredUser({ dispatch, getters }, user) {
            return new Promise((resolve, reject) => {
                // On deleting the user, the user should be removed from existing groups  
                dispatch('removeDeletedUserFromGroup', user)
                fb.registeredUsersCollection.doc(user.id).delete()
                    .then(() => {
                        resolve('User deleted successfully')
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },

        // Invited
        deleteInvitedUser({ state }, userId) {
            return new Promise((resolve, reject) => {
                fb.invitedUsersCollection.doc(userId).delete()
                    .then(() => {
                        resolve('User deleted successfully')
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },

        // Invite User
        inviteNewUser({ state }, user) {
            return new Promise((resolve, reject) => {
                fb.invitedUsersCollection.add({
                    name: user.name,
                    email: user.email,
                    userRole: user.userRole,
                    fdn: user.fdn,
                    onCall: false,
                    createdOn: new Date(),
                }).then(() => {
                    resolve('Invitation sent successfully.');
                })
                    .catch(error => {
                        reject(error.message);
                    })

            })
        },

        getInvitedUserInformation({ commit }, uId) {
            return new Promise((resolve, reject) => {
                fb.invitedUsersCollection.doc(uId).get()
                    .then(userData => {
                        commit('setInvitedUserProfile', userData.data());
                        resolve('Fetched User Information');
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },
    }
}