<template>
    <v-menu :close-on-content-click="false" open-on-hover bottom left offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
                <span class="text-capitalize mr-2">
                    {{ userProfile.data().name }}
                    <span v-if="userProfile.data().userRole === 'Admin'"> (Admin) </span>
                </span>
                <v-badge dot bordered offset-x="13" offset-y="38" :color="getColor(userProfile.data().onlineStatus)">
                    <v-avatar size="45">
                        <v-icon x-large dark> mdi-account-circle </v-icon>
                    </v-avatar>
                </v-badge>
            </v-btn>
        </template>
        <v-list>
            <v-list-group v-model="item" no-action>
                <template v-slot:activator>
                    <v-list-item>
                        <v-icon dense class="mr-4" :color="getColor(userProfile.data().onlineStatus)">
                            mdi-checkbox-blank-circle
                        </v-icon>
                        <v-list-item-title v-text="userProfile.data().onlineStatus">
                        </v-list-item-title>
                    </v-list-item>
                </template>
                <v-list-item v-for="(status, i) in statuses" :key="i" class="pa-0">
                    <v-btn text block @click="changeOnlineStatus(status)" class="font-weight-regular text-capitalize">
                        <v-col align="end">
                            {{ status }}
                        </v-col>
                        <v-col align="start" cols="4">
                            <v-icon right small :color="getColor(status)">
                                mdi-checkbox-blank-circle
                            </v-icon>
                        </v-col>
                    </v-btn>
                </v-list-item>
            </v-list-group>
            <v-divider></v-divider>
            <v-btn class="text-capitalize font-weight-regular" text @click="navigate('/myProfile')" block>
                My Profile
            </v-btn>
            <v-btn class="text-capitalize font-weight-regular" color="error" text block @click="logout">
                Sign-Out
            </v-btn>
        </v-list>
    </v-menu>
</template>
<script>
import { mapGetters } from 'vuex';
import router from '../../../router';
export default {
    name: 'profileMenu',
    computed: {
        ...mapGetters(['userProfile'])
    },
    data() {
        return {
            item: false,
            statuses: ["Available", "Unavailable", "Busy"],
        }
    },
    methods: {
        changeOnlineStatus(status) {
            if (status === "Available") {
                this.$store.dispatch("setUserAvailable");
            }
            if (status === "Unavailable") {
                this.$store.dispatch("setUserUnavailable");
            }
            if (status === "Busy") {
                this.$store.dispatch("setUserBusy");
            }
        },
        getColor(status) {
            if (status === "Unavailable") {
                this.disable = false;
                return "error";
            }
            if (status === "Busy") {
                this.disable = true;
                return "amber";
            }
            if (status === "Available") {
                this.disable = false;
                return "success";
            }
        },
        navigate(link) {
            if (router.currentRoute.path === "/liveCall") {
                window.open(link, "_blank");
            } else {
                router.push(link);
            }
        },
        logout() {
            this.$store.dispatch("logout").then(
                (response) => {
                    this.$store.dispatch("showSnackBar", {
                        text: response,
                        color: "success",
                    });
                },
                (error) => {
                    this.$store.dispatch("showSnackBar", {
                        text: error,
                        color: "error",
                    });
                }
            );
        },
    }
}
</script>