<template>
    <v-footer class="main" v-if="$router.currentRoute.path !== '/login'">
        <v-col cols="12" class="text-center grey--text">
            {{ new Date().getFullYear() }} — <strong>ARSconnect </strong>
            <span class="text-caption">
                (Beta)
            </span>
            <v-spacer></v-spacer>
            <!-- <span> Screen Size - {{ getScreenSize }}</span> -->
        </v-col>
    </v-footer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'dasfooter',
    computed: {
        ...mapGetters(['getScreenSize'])
    }
}
</script>