import * as fb from "../../firebase";
import router from "../../router";

export default {
    state: {
        invitedUserProfile: {},
        userProfile: {},
        userId: null,
        userRole: null
    },
    getters: {
        userProfile: state => state.userProfile,
        getInvitedUserProfile: state => state.invitedUserProfile,
        userId: state => state.userId,
    },
    mutations: {
        setUserProfile(state, data) {
            state.userProfile = data;
        },
        setInvitedUserProfile(state, data) {
            state.invitedUserProfile = data;
        },
        setUserId(state, uid) {
            state.userId = uid;
        },
    },
    actions: {
        login({ dispatch }, form) {
            return new Promise((resolve, reject) => {
                fb.auth.signInWithEmailAndPassword(form.email, form.password).then(cred => {
                    console.log(`user logged In: ${cred.user.uid}`);
                    //fetch user profile
                    dispatch('fetchUserProfile', cred.user);
                    resolve('Successfully Logged In');
                })
                    .catch(error => {
                        reject(error.message)
                        //Todo: add logging mechanism to be stored on firebase for each account
                    })
            })
        },
        logout({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                //set user Unavailable
                dispatch('setUserUnavailable');
                fb.auth.signOut()
                    .then(() => {
                        commit('setUserProfile', {});
                        commit('setUserId', null);
                        commit('setUserRole', null)
                        router.push('/login');
                        resolve('Successfully Logged Out')
                        location.reload();
                    })
                    .catch(error => {
                        reject(error.message);
                    })

            })
        },
        createAdminAccount({ dispatch }, form) {
            return new Promise((resolve, reject) => {
                fb.auth.createUserWithEmailAndPassword(form.email, form.password)
                    .then(cred => {
                        //create user profile in usercollection
                        fb.registeredUsersCollection.doc(cred.user.uid).set({
                            name: form.name,
                            email: form.email,
                            userRole: form.userRole,
                            createdOn: new Date(),
                            onlineStatus: 'Unavailable',
                            groups: []
                        }).then(() => {
                            dispatch('initiateAppData')
                                .then(() => {
                                    resolve('Account Created Successfully.');
                                })
                        })
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },
        createAccount({ commit, dispatch }, form) {
            return new Promise((resolve, reject) => {
                fb.auth.createUserWithEmailAndPassword(form.email, form.password)
                    .then(cred => {
                        //create user profile in usercollection
                        fb.registeredUsersCollection.doc(cred.user.uid).set({
                            name: form.name,
                            email: form.email,
                            userRole: form.userRole,
                            createdOn: new Date(),
                            onlineStatus: 'Unavailable',
                            groups: []
                        }).then(() => {

                            dispatch('getAssignedRegisteredUsers', [cred.user.uid])
                            fb.invitedUsersCollection.doc(form.invitationId).delete()
                                .then(() => {
                                    fb.auth.signOut();
                                    commit('setUserProfile', {});
                                    commit('setUserId', null);
                                    resolve('Account Created Successfully. Login to Continue');
                                    router.push('/login');
                                })
                        })
                    })
                    .catch(error => {
                        reject(error.message);
                        //Todo: add logging mechanism to be stored on firebase for each account
                    })
            })
        },
        fetchUserProfile({ commit, state }, user) {
            if (state.userId === null) {
                console.log('Inside fetch User Profile')
                //fetch user profile data
                fb.registeredUsersCollection.doc(user.uid)
                    .onSnapshot(userData => {
                        //set userProfile in state
                        commit('setUserProfile', userData);
                        commit('setUserId', user.uid);
                        commit('setShowNav', true);
                        if (router.currentRoute.path === '/login' || router.currentRoute.path === '/adminLogin') {
                            router.push('/')
                        }
                    });
            }
        },
        manageUserPresence({ getters }, user) {
            //let session = Math.round(Math.random() * 1000)
            var userStatusDatabaseRef = fb.firebase.database().ref('/status/' + user.uid);
            var myStorage = window.sessionStorage;
            if (getters.userProfile.data().onlineStatus === 'Busy') {
                var isOnlineForDatabase = {
                    state: 'Busy',
                    last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
                };
            } else {
                isOnlineForDatabase = {
                    state: 'Available',
                    last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
                };
            }
            fb.firebase.database().ref('.info/connected').on('value', function (snapshot) {
                // If we're not currently connected, don't do anything.
                if (snapshot.val() === false) return;
                userStatusDatabaseRef.push(isOnlineForDatabase).then(response => {
                    myStorage.setItem('sessionID', response.key.toString());
                    var userStatusDatabaseRefDel = fb.firebase.database().ref('/status/' + user.uid + '/' + response.key);
                    userStatusDatabaseRefDel.onDisconnect().remove()
                })
            });

        },
        setUserAvailable({ state }) {
            fb.userCollection.doc(state.userId).update({
                onlineStatus: 'Available',
            })
        },
        setUserBusy({ state }) {
            fb.userCollection.doc(state.userId).update({
                onlineStatus: 'Busy',
            })
        },
        setUserUnavailable({ state }) {
            fb.userCollection.doc(state.userId).update({
                onlineStatus: 'Unavailable',
            })
        },

    }
}