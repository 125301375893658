import * as fb from '../../firebase/index.js'
export default {
    state: {
        userRoles: [],
        assignedRole: undefined,
        userRole: null
    },
    getters: {
        userRoles: state => state.userRoles,
        assignedRole: state => state.assignedRole,
        userRole: state => state.userRole
    },
    mutations: {
        setUserRoles(state, userRoles) {
            state.userRoles = userRoles
        },
        setAssignedRole(state, role) {
            state.assignedRole = role
        },
        setUserRole(state, role) {
            state.userRole = role
        }
    },
    actions: {
        getUserRoles({ commit, getters }) {
            let roles = [];
            return new Promise((resolve, reject) => {
                fb.userRolesCollection.onSnapshot(snapshot => {

                    snapshot.docs.forEach(role => {
                        roles.push({ data: role.data(), id: role.id });
                    })
                    commit('setUserRoles', roles);
                    resolve('Fetched all User Roles')
                })
                // .catch(error => {
                //   reject(error.message);
                // })
            })
        },
        addNewRole({ dispatch }, role) {
            return new Promise((resolve, reject) => {
                const newRole = {
                    name: role.name,
                    description: role.description,
                    permissions: role.permissions,
                    dateCreated: new Date(),
                    lastUpdated: new Date(),
                    userCount: 0
                };
                fb.userRolesCollection.add(newRole)
                    .then(() => {
                        resolve('New role added successfully')
                        console.log('New role added successfully')
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },
        createDefaultRoles({ dispatch }) {
            dispatch('addNewRole', {
                name: 'Super Admin',
                description: 'This is the super Admin with all level of Access',
                permissions: {
                    config: true,
                    roles: true,
                    user: {
                        add: true,
                        read: true,
                        write: true,
                        delete: true
                    },
                    kiosk: {
                        add: true,
                        read: true,
                        write: true,
                        delete: true
                    },
                    orgGrp: {
                        add: true,
                        read: true,
                        write: true,
                        delete: true
                    }
                }
            })
            dispatch('addNewRole', {
                name: 'General',
                description: 'This role is assigned to the user created using authentication service',
                permissions: {
                    config: false,
                    roles: false,
                    user: {
                        add: false,
                        read: true,
                        write: false,
                        delete: false
                    },
                    kiosk: {
                        add: false,
                        read: true,
                        write: false,
                        delete: false
                    },
                    orgGrp: {
                        add: false,
                        read: true,
                        write: false,
                        delete: false
                    }
                }
            })
            dispatch('addNewRole', {
                name: 'Admin',
                description: 'This role is assigned to the user created using authentication service',
                permissions: {
                    config: false,
                    roles: true,
                    user: {
                        add: true,
                        read: true,
                        write: true,
                        delete: true
                    },
                    kiosk: {
                        add: true,
                        read: true,
                        write: true,
                        delete: true
                    },
                    orgGrp: {
                        add: true,
                        read: true,
                        write: true,
                        delete: true
                    }
                }
            })
        }
    }
}