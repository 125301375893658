<template>
    <v-app-bar app flat dark color="navBarColor">
        <v-spacer></v-spacer>
        <v-toolbar-items>
            <notifications></notifications>
            <profileMenu></profileMenu>
        </v-toolbar-items>
    </v-app-bar>
</template>
<script>
import notifications from './notifications.vue'
import profileMenu from './profileMenu.vue'
export default {
    name: 'navBar',
    components:{
        notifications,
        profileMenu
    }
}
</script>