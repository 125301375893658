import * as fb from '../../firebase/index.js'
import axios from 'axios'
export default {
    state: {
        userGroups: []
    },
    getters: {
        userGroups: state => state.userGroups,
    },
    mutations: {
        setUserGroups(state, userGroup) {
            state.userGroups.push(userGroup);
        }
    },
    actions: {
        getUserGroups({ commit, getters }) {
            let groups = [];
            return new Promise((resolve, reject) => {
                fb.userGroupsCollection.onSnapshot(querySnapshot => {
                    groups = []
                    if (querySnapshot.empty) {
                        resolve(null);
                    } else {
                        querySnapshot.forEach(group => {
                            let data = group.data()
                            data["id"] = group.id
                            let index = getters.userGroups.findIndex(grp => grp.id === group.id)
                            if (index !== -1) {
                                getters.userGroups.splice(index, 1)
                            }
                            commit('setUserGroups', data)
                        });
                    }
                    resolve("Feteched all user groups")
                })
            })
        },

        getAssignedUserGroups({ commit, getters, dispatch }) {
            console.log('Got Called')
            return new Promise((resolve, reject) => {
                fb.userGroupsCollection.where("users", "array-contains", getters.userId).onSnapshot(snapshot => {
                    snapshot.docChanges().forEach(change => {
                        console.log('Changed Data: ', change.doc.data())
                        if (change.type === "added") {
                            console.log('Org Group Added')
                            let data = change.doc.data()
                            data["id"] = change.doc.id
                            commit('setUserGroups', data)
                            dispatch('getAssignedRegisteredUsers', change.doc.data().users)
                            dispatch('getAllAssignedKiosks', change.doc.data().kiosk)
                        } else if (change.type === "modified") {
                            console.log('Modified Group Object: ', change.doc.data())
                            let index = getters.userGroups.findIndex(grp => grp.id === change.doc.id)
                            if (index !== -1) {
                                getters.userGroups.splice(index, 1)
                            }
                            let data = change.doc.data()
                            data["id"] = change.doc.id
                            commit('setUserGroups', data)
                        } else {
                            let index = getters.userGroups.findIndex(grp => grp.id === change.doc.id)
                            if (index !== -1) {
                                getters.userGroups.splice(index, 1)
                            }
                            console.log('Removed Group Object: ', change.doc.data())
                        }
                    })
                })
                resolve('Fetched')
            })
        },
        addNewGroup({ dispatch }, group) {
            return new Promise((resolve, reject) => {
                const newGroup = {
                    name: group.name,
                    description: group.description,
                    dateCreated: new Date(),
                    lastUpdated: new Date(),
                    userCount: group.users.length,
                    users: group.users,
                    kiosk: group.assignedKiosks
                };
                fb.userGroupsCollection.add(newGroup)
                    .then(response => {
                        dispatch('getUserGroups');
                        resolve('New group added successfully')
                        // Add group ID to kiosk and user
                        console.log('Added Group: ', group)
                        dispatch('addGroupToUser', {
                            users: group.users,
                            id: response.id
                        })
                        dispatch('addGroupToKiosk', {
                            kiosks: group.assignedKiosks,
                            id: response.id
                        })
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },

        addGroupToUser({ getters }, group) {
            group.users.forEach(user => {
                fb.registeredUsersCollection.doc(user).update({
                    groups: fb.firebase.firestore.FieldValue.arrayUnion(
                        group.id
                    )
                })
            })
        },
        addGroupToKiosk({ getters }, group) {
            group.kiosks.forEach(kiosk => {
                fb.kioskCollection.doc(kiosk).update({
                    groups: fb.firebase.firestore.FieldValue.arrayUnion(
                        group.id
                    )
                })
            })
        },
        updateGroup({ dispatch, getters }, group) {
            return new Promise((resolve, reject) => {
                let group1 = getters.userGroups.filter(grp => grp.id === group.id)
                let differenceUser = group1[0].users.filter(x => !group.users.includes(x));
                differenceUser.forEach(user => {
                    if (group1[0].users.includes(user)) {
                        fb.registeredUsersCollection.doc(user).update({
                            groups: fb.firebase.firestore.FieldValue.arrayRemove(
                                group.id
                            )
                        })
                    }
                })

                let differenceUser2 = group.users.filter(x => !group1[0].users.includes(x));

                differenceUser2.forEach(user => {

                    fb.registeredUsersCollection.doc(user).update({
                        groups: fb.firebase.firestore.FieldValue.arrayUnion(
                            group.id
                        )
                    })
                })

                fb.userGroupsCollection.doc(group.id).update({
                    name: group.name,
                    description: group.description,
                    lastUpdated: new Date(),
                    users: group.users,
                    kiosk: group.kiosk
                })
                    .then(() => {
                        resolve('User group updated successfully.');
                    })
                    .catch(error => {
                        reject(error.message)
                    })
            })

        },
        deleteGroup({ dispatch }, groupId) {

            return new Promise((resolve, reject) => {
                fb.userGroupsCollection.doc(groupId.id).delete()
                    .then(() => {
                        const config = {
                            method: 'post',
                            url: 'https://us-central1-florida-dist-11.cloudfunctions.net/apiV2/deleteUserGroup/a763cd056f1b2405788443b7197e0708',
                            params: { users: groupId.users, groupName: groupId.id }
                        }
                        axios(config)
                            .then(result => {
                                console.log('Result from axios call', result)
                            })
                            .catch(error => {
                                console.log('Error : ', error.message)

                            })
                        dispatch('getUserGroups');
                        resolve('Group deleted successfully.')
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },

        removeDeletedUserFromGroup({ state }, user) {
            console.log('User: ', user)
            user.groups.forEach(group => {
                fb.userGroupsCollection.doc(group).update({
                    users: fb.firebase.firestore.FieldValue.arrayRemove(
                        user.id
                    )
                })
            })
        },

        removeDeletedKioskFromGroup({ state }, kiosk) {
            console.log('Kiosk: ', kiosk.data())
            kiosk.data().groups.forEach(group => {
                fb.userGroupsCollection.doc(group).update({
                    kiosk: fb.firebase.firestore.FieldValue.arrayRemove(
                        kiosk.id
                    )
                })
            })
        }
    }
}