import * as fb from '../../firebase/index.js'

export default {
    state: {
        allRegisteredKiosks: [],
        allInvitedKiosks: [],
        kioskAnalytics: {
            qnaAnalytics: [],
            userSessionAnalytics: [],
            totalUserSessions: 0,
            totalQuestionsAsked: 0,
            overallPerformance: 0,
        },
    },
    getters: {
        allRegisteredKiosks: state => state.allRegisteredKiosks,
        allInvitedKiosks: state => state.allInvitedKiosks,
    },
    mutations: {
        setAllRegisteredKiosks(state, kiosks) {
            state.allRegisteredKiosks.push(kiosks)
        },
        setAllInvitedKiosks(state, kiosks) {
            state.allInvitedKiosks = kiosks;
        },
    },
    actions: {
        inviteNewKiosk({ state }, kiosk) {
            return new Promise((resolve, reject) => {
                fb.invitedKiosksCollection.add({
                    name: kiosk.name,
                    email: kiosk.email,
                    description: kiosk.description,
                    location: kiosk.location,
                    fdn: kiosk.fdn,
                    createdOn: new Date(),
                }).then(() => {
                    resolve('Kiosk Invitation sent successfully.');
                })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },
        addNewKiosk({ dispatch }, kiosk) {
            return new Promise((resolve, reject) => {
                fb.auth.createUserWithEmailAndPassword(kiosk.email, kiosk.password)
                    .then(cred => {
                        fb.kioskCollection.doc(cred.user.uid).set({
                            name: kiosk.name,
                            email: kiosk.email,
                            location: kiosk.location,
                            description: kiosk.description,
                            onlineStatus: 'Unavailable',
                            groups: [],
                            createdOn: new Date(),
                        }).then(() => {
                            fb.invitedKiosksCollection.doc(kiosk.invitationId).delete()
                                .then(() => {
                                    fb.auth.signOut();
                                    resolve('Kiosk account activated successfully.');
                                })
                        })
                    })
                    .catch(error => {
                        reject(error.message)
                    })
            })
        },

        getAllRegisteredKiosks({ commit, getters }) {
            return new Promise((resolve, reject) => {
                fb.kioskCollection.onSnapshot(snapshot => {

                    snapshot.docs.forEach(snap => {
                        let index = getters.allRegisteredKiosks.findIndex(kiosk => kiosk.id === snap.id)

                        if (index !== -1) {
                            getters.allRegisteredKiosks.splice(index, 1)
                        }
                        if (snap.exists) {
                            commit('setAllRegisteredKiosks', snap)
                        }

                    })
                })
            })
        },
        getAllAssignedKiosks({ commit, getters }, kiosks) {
            return new Promise((resolve, reject) => {
                kiosks.forEach(kiosk => {
                    fb.kioskCollection.doc(kiosk).onSnapshot(snapshot => {
                        let index = getters.allRegisteredKiosks.findIndex(kiosk => kiosk.id === snapshot.id)
                        if (index !== -1) {
                            getters.allRegisteredKiosks.splice(index, 1)
                        }
                        commit('setAllRegisteredKiosks', snapshot)
                    })
                })
            })
        },
        getAllInvitedKiosks({ commit }) {
            return new Promise((resolve, reject) => {
                fb.invitedKiosksCollection.onSnapshot(snapshot => {
                    if (snapshot.empty) console.log('Empty Kiosk collection');
                    commit('setAllInvitedKiosks', snapshot.docs);
                    resolve('Fetched all Invited Kiosks in collection');
                }, error => {
                    reject(error.message);
                })

            })
        },
    }
}